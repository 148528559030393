<template>
  <div class="main">
    <div style="min-height: 700px; padding-top: 20px; background: #fff">
      <div class="itemName">
        <el-divider>
          <div class="dividerFont">
            <span>权益中心</span>
            <div
              style="width: 60px; height: 2px; background-color: #029aff"
            ></div>
          </div>
        </el-divider>
      </div>
      <!-- <div class="now-info">
        <div class="left">
          您当前的会员等级: <span>{{ userGroup.name }}</span>
        </div>
        <div class="right">会员有效期: <span>永久</span></div>
      </div> -->
      <!-- <div class="label-tab">
        <div class="title-name">剩余次数</div>
      </div> -->
      <!-- <div class="now-info">
        <div class="left">
          您当前的会员等级: <span>{{ userGroup.name }}</span>
        </div>
        <div class="right">会员有效期: <span>永久</span></div>
      </div>
      <div class="label-tab">
        <img
          class="vip-img"
          src="@/assets/personCenter/memberCenter/vip-log.png"
          alt=""
        />
        <div class="title-name">会员</div>
      </div>
      <div class="vip-list">
        <div
          class="list"
          v-for="(item, index) in group"
          :key="index"
          :style="{ backgroundImage: `url(${list[index].url})` }"
        >
          <div class="vip-name">{{ item.name }}</div>
          <div class="vip-pay">
            <span class="money">{{ item.price }}</span> 元/年
          </div>
          <div class="btn" @click="toPayVip(item)" v-if="item.price > 0">立即购买</div>
          <div class="label" v-if="group.length == index + 1">超值推荐</div>
        </div>
      </div> -->
      <div class="label-tab">
        <!-- <img
          class="vip-img"
          src="@/assets/personCenter/memberCenter/vip-log.png"
          alt=""
        /> -->
        <div class="title-name">权益充值</div>
      </div>


      <!-- <div
        class="craftsman"
        v-for="(info, inx) in equitiesList.slice(0, 1)"
        :key="inx"
      >
        <div class="tab-name">
          <div class="name-info">
            {{ info.name }}
          </div>
          <div class="name-contend">
            {{ info.remark }}
          </div>
        </div>
        <div class="craftsman-info">
          <div
            v-for="(data, val) in info.configs"
            :key="val"
            :class="[tabIndex == data.id ? 'configs' : 'con-info']"
            @click="tabData(data.id)"
          >
            <div class="left">
              <div class="ycc">
                {{ data.num }}
                <span>次</span>
              </div>
              <div class="lyc">{{ payNum(data.value, data.num) }}元/次</div>
            </div>

            <div class="lyy">{{ data.value }}元</div>
            <img
              src="@/assets/personCenter/memberCenter/ggg.png"
              mode=""
              class="ggg"
            />
          </div>
        </div>

        <div class="buy" v-if="info.configs.length > 0">
          <div class="getVip" @click="toPurchase(info)">立即购买</div>
        </div>
      </div> -->
      <!-- <div class="vip-list">
        <div
          class="list"
          v-for="(item, index) in group"
          :key="index"
          :style="{ backgroundImage: `url(${list[index].url})` }"
        >
          <div class="vip-name">{{ item.name }}</div>
          <div class="vip-pay">
            <span class="money">{{ item.price }}</span> 元/年
          </div>
          <div class="btn" @click="toPayVip(item)">立即购买</div>
          <div class="label" v-if="list.length == index + 1">超值推荐</div>
        </div>
      </div> -->
      <!-- <div class="vip-title">
        <div class="left-solid"></div>
        <div class="title-tip">
          <img
            class="vip-img"
            src="@/assets/personCenter/memberCenter/vip-log.png"
            alt=""
          />
          <div class="title-name">专享特权</div>
        </div>
        <div class="right-solid"></div>
      </div> -->
      <!-- <div class="vip-privilege">
        <div class="vip-info-title">
          <div
            class="equities-info"
            v-for="(item, index) in equities"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="vip-info-title" v-for="(data, dex) in group" :key="dex">
          <div class="group-title">{{ data.name }}</div>
          <div
            class="groupEquities"
            v-for="(info, inx) in data.groupEquities"
            :key="inx"
          >
            <span v-if="parseInt(info.value)">{{ info.value }}次</span>
            <span v-else>
              <img
                v-if="info.value"
                class="gou-img"
                src="@/assets/personCenter/memberCenter/cha-fill.png"
                alt=""
              />
              <img
                v-if="info.value"
                class="gou-img"
                src="@/assets/personCenter/memberCenter/gou-fill.png"
                alt=""
              />
              <img
                v-if="data.groupEquities.length == inx + 1"
                class="gou-img"
                src="@/assets/personCenter/memberCenter/gou-show.png"
                alt=""
              />
            </span>
          </div>
        </div>
      </div> -->
      <div class="vip-title">
        <div class="left-solid"></div>
        <div class="title-tip">
          <div class="title-name">服务权益</div>
        </div>
        <div class="right-solid"></div>
      </div>
      <div class="data-info">
        <div class="info-tab" v-for="(info, inx) in equitiesList" :key="inx">
          <div class="tab-name">
            <div class="name-info">
              {{ info.name }}
            </div>
            <div class="name-contend">
              {{ info.remark == 'null' ? '' : info.remark }}
            </div>
          </div>
          <div
            v-for="(data, val) in info.configs"
            :key="val"
            :class="[tabIndex == data.id ? 'configs' : 'con-info']"
            @click="tabData(data.id)"
          >
            <div class="left">
              <div class="ycc">
                {{ data.num }}
                <span>次</span>
              </div>
              <div class="lyc">{{ payNum(data.value, data.num) }}元/次</div>
            </div>

            <div class="lyy">{{ data.value }}元</div>
            <img
              src="@/assets/personCenter/memberCenter/ggg.png"
              mode=""
              class="ggg"
            />
          </div>

          <div class="buy" v-if="info.configs.length > 0">
            <div class="getVip" @click="toPurchase(info)">立即购买</div>
          </div>
        </div>
      </div>
    </div>
    <pay :dialogVisible="dialogVisible" :payInfo="payInfo"></pay>
  </div>
</template>

<script>
import {
  selectEquity,
  jobOrderItem,
} from "@/api/personalCenter/personalCenter";
import { aliPcPay } from "@/api/pay/pay";
import pay from "@/components/pay/pay";
export default {
  components: {
    pay,
  },
  data() {
    return {
      list: [
        {
          url: require("@/assets/personCenter/memberCenter/vip-bg.png"),
        },
        {
          url: require("@/assets/personCenter/memberCenter/vip-bg.png"),
        },
        {
          url: require("@/assets/personCenter/memberCenter/vip-bg.png"),
        },
        {
          url: require("@/assets/personCenter/memberCenter/vip-bg.png"),
        },
        {
          url: require("@/assets/personCenter/memberCenter/vip-bg.png"),
        },
        {
          url: require("@/assets/personCenter/memberCenter/vip-bg.png"),
        },
      ],
      equities: [],
      equitiesList: "",
      group: [],
      userGroup: "",
      tabIndex: "",
      dialogVisible:false,
      payInfo:{}
    };
  },
  created() {
    // this.params.jobUserId = localStorage.getItem("userId");
    this.getjobFairList();
  },
  methods: {
    // 获取会员列表
    async getjobFairList() {
      let params = {
        userType: 1,
        jobUserId: localStorage.getItem("userId"),
      };
      let res = await selectEquity(params);
      console.log(res, "vip");
      let data = [{ name: "专属特权" }];
      res.data.equities.map((item) => {
        data.push(item);
        return item;
      });
      this.equities = data;
      this.equitiesList = res.data.equities;
      res.data.group.map((item) => {
        if (item.groupEquities.length < res.data.equities.length) {
          let a = res.data.equities.length - item.groupEquities.length;
          for (let i = 1; i <= a; i++) {
            let par = {
              value: false,
            };
            item.groupEquities.push(par);
          }
        }
      });
      this.group = res.data.group;
      // let id = res.data.self[0].jobVipGroupId;
      // this.userGroup = this.group.filter((i) => i.id == id)[0];
      console.log(this.group);
    },
    tabData(index) {
      this.tabIndex = index;
      this.dialogVisible =false;
    },
    payNum(value, num) {
      let number = value / num;
      return number.toFixed(2);
    },
    toPurchase(value) {
      let val = value.configs.filter((i) => i.id == this.tabIndex);
      if (val.length > 0) {
        this.dialogVisible = true;
        let params = {
          amount: val[0].value,
          num: val[0].num,
          orderType: 2,
          payMethod: 2,
          productId: value.id,
          productName: value.name,
        };
        params.userType = 1;
        params.jobUserId = localStorage.getItem("userId");
        // this.$confirm("是否够买此权益?", "提示", {
        //   confirmButtonText: "确定",
        //   cancelButtonText: "取消",
        //   type: "warning",
        // })
        // .then(() => {
        this.payInfo = params;
        // let id = "";
        // jobOrderItem(id, params)
        //   .then((res) => {
        //     console.log(res);

        //     var order = res.data;
        //     order.outTradeNo = order.orderNo;
        //     order.amount = 0.01;
        //     // 支付
        //     // aliPcPay(order).then((res) => {
        //     //   console.log(res);

        //     //   const newWindow = window.open("", "_blank");
        //     //   newWindow.document.write(res);
        //     //   newWindow.focus();
        //     // });

        //     this.$message({
        //       type: "success",
        //       message: "购买成功!",
        //     });
        //   })

        //   .catch((error) => {
        //     this.$message({
        //       type: "error",
        //       message: "购买失败",
        //     });
        //   });

        // })
        // .catch((error) => {
        //   console.log(error);
        //   this.$message({
        //     type: "info",
        //     message: "已取消购买",
        //   });
        // });
      } else {
        this.$message({
          type: "info",
          message: "请选择购买权益",
        });
      }
    },
    toPayVip(value) {
        this.dialogVisible = true;
        let params = {
          amount: value.price,
          num: 1,
          orderType: 1,
          payMethod: 2,
          productId: value.id,
          productName: value.name,
        };
        params.userType = 1;
        params.jobUserId = localStorage.getItem("userId");
        this.payInfo = params;
      // let id = "";
      // let info = value;
      // let itemData = [];
      // info.groupEquities.map((item) => {
      //   if (item.value != false) {
      //     let params = {
      //       num: "",
      //       productId: "",
      //       productName: "",
      //     };
      //     params.productName = item.ename;
      //     params.productId = item.jobVipEquityId;
      //     params.num = item.value;
      //     itemData.push(params);
      //   }
      //   return item;
      // });
      // let params = {
      //   amount: info.price, //价格
      //   itemParams: [...itemData], //权益信息
      //   num: 1, //购买次数
      //   orderType: 1, //支付状态 vip(1),服务权益(2),购买课程(3)
      //   payMethod: 2, //支付方式 微信,支付宝
      //   productId: info.id,
      //   productName: info.name,
      // };
      // params.userType = 1;
      // params.jobUserId = localStorage.getItem("userId");
      // this.$confirm("是否够买此权益?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(() => {
      //     console.log(params);
      //     jobOrderItem(id, params)
      //       .then((res) => {
      //         this.$message({
      //           type: "success",
      //           message: "购买成功!",
      //         });
      //       })
      //       .catch((error) => {
      //         this.$message({
      //           type: "error",
      //           message: "购买失败",
      //         });
      //       });
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: "info",
      //       message: "已取消购买",
      //     });
      //   });
    },
  },
};
</script>

<style scoped lang="scss">
.itemName {
  padding: 0 86px;
  padding-bottom: 22px;
  .dividerFont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    span {
      font-size: 24px;
      margin-bottom: 6px;
    }
  }
}
.now-info {
  width: 95%;
  display: flex;
  margin: 0 auto;
  font-size: 14px;
  color: #666666;
  .left {
    margin-right: 40px;
  }
}
.label-tab {
  width: 91%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: 20px;
  padding: 10px 15px;
  background: linear-gradient(to bottom, #ffe3c2, #fbd5b0);
  border-radius: 15px 15px 0 0;
  color: #d79671ff;
  .vip-img {
    width: 39px;
    height: 16px;
    margin-right: 10px;
  }
}
.vip-list {
  width: 94%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
  padding: 30px 0;
  .list {
    width: 31%;
    height: 148px;
    border-radius: 11px;
    background-repeat: no-repeat;
    background-size: 100%;
    box-sizing: border-box;
    padding: 20px 30px;
    margin: 0 20px 20px 0;
    color: #ffffff;
    position: relative;
    .vip-name {
      font-size: 20px;
      margin-bottom: 25px;
    }
    .vip-pay {
      font-size: 12px;
      .money {
        font-size: 18px;
        font-weight: bold;
      }
    }
    .btn {
      width: 80px;
      height: 24px;
      font-size: 12px;
      line-height: 24px;
      border-radius: 24px;
      margin-top: 10px;
      background: linear-gradient(to bottom, #ffe3c2, #fbd5b0);
      text-align: center;
      color: #d5936eff;
    }
    .label {
      width: 60px;
      height: 19px;
      font-size: 12px;
      text-align: center;
      line-height: 19px;
      border-radius: 0 0 5px 5px;
      background-color: #fb6962ff;
      // box-shadow: 0 0 4px #FB6962FF;
      position: absolute;
      top: -4px;
      right: 15px;
    }
  }
}
.vip-title {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  .left-solid {
    width: 0;
    height: 0;
    border-top: 1px solid transparent;
    border-right: 50px solid #d69572;
    border-bottom: 1px solid transparent;
    margin: auto 0;
  }
  .title-tip {
    width: 20%;
    display: flex;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    color: #d79571;
    margin: 0 30px;
    font-size: 20px;
    .vip-img {
      width: 48px;
      height: 20px;
      margin-right: 10px;
    }
  }
  .right-solid {
    width: 0;
    height: 0;
    border-top: 1px solid transparent;
    border-left: 50px solid #d69572;
    border-bottom: 1px solid transparent;
    margin: auto 0;
  }
}
.vip-privilege {
  width: 94%;
  margin: 0 auto;
  padding: 30px 0;
  display: flex;
  .vip-info-title {
    width: 30%;
    .equities-info {
      width: 100%;
      height: 38px;
      line-height: 38px;
      text-align: center;
      font-size: 14px;
    }
    .equities-info:first-child {
      font-size: 18px;
      color: #999999;
    }
    .equities-info:nth-child(odd) {
      background-color: #fffbf7;
    }
    .group-title {
      width: 100%;
      height: 38px;
      line-height: 38px;
      text-align: center;
      font-size: 18px;
      color: #999999;
      background-color: #fffbf7;
    }
    .groupEquities {
      width: 100%;
      height: 38px;
      line-height: 38px;
      text-align: center;
      font-size: 14px;
      .gou-img {
        width: 20px;
        height: 20px;
      }
    }
    .groupEquities:nth-child(odd) {
      background-color: #fffbf7;
    }
  }
  .vip-info-title:last-child {
    color: #d5936e;
    .group-title {
      color: #d5936e;
    }
  }
}
.data-info {
  width: 94%;
  margin: 0 auto;
  display: flex;
  flex-flow: wrap;
  .info-tab {
    width: 48%;
    border: 1px solid #f2cdb8;
    border-radius: 15px;
    box-sizing: border-box;
    padding: 10px 6px;
    margin: 10px 0;

    .tab-name {
      display: flex;
      justify-content: space-around;
      margin-bottom: 20px;

      .name-info {
        font-size: 14px;
        text-align: center;
        margin: auto 0;
      }

      .name-contend {
        width: 60%;
        font-size: 12px;
        background: #eff1f7;
        border-radius: 12px;
        box-sizing: border-box;
        padding: 5px 20px;
        color: #999999;
        word-wrap: break-word;
        word-break: normal;
      }
    }

    .configs {
      display: flex;
      justify-content: space-around;
      width: 96%;
      height: 58px;
      margin: 15px auto;
      box-sizing: border-box;
      padding: 10px;
      text-align: center;
      border-radius: 6px;
      background-image: url("~@/assets/personCenter/memberCenter/mb.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: relative;
      border: none;
      .left {
        display: flex;
        flex: 1;
        .ycc {
          width: 30%;
          margin: auto 0;
          font-size: 24px;
          span {
            font-size: 14px;
          }
        }

        .lyc {
          width: 50%;
          font-size: 18px;
          margin: auto 0;
          color: rgba(153, 153, 153, 1);
        }
      }

      .lyy {
        width: 20%;
        margin: auto 0;
        font-size: 18px;
      }

      .ggg {
        width: 10px;
        height: 14px;
        position: absolute;
        right: 2px;
        top: 0;
      }
    }

    .con-info {
      display: flex;
      justify-content: space-between;
      width: 94%;
      height: 58px;
      margin: 15px auto;
      box-sizing: border-box;
      padding: 10px;
      text-align: center;
      border-radius: 6px;
      border: 2px solid rgba(201, 201, 201, 1);

      .left {
        display: flex;
        flex: 1;
        .ycc {
          width: 30%;
          margin: auto 0;
          font-size: 24px;
          span {
            font-size: 14px;
          }
        }

        .lyc {
          width: 50%;
          font-size: 18px;
          margin: auto 0;
          color: rgba(153, 153, 153, 1);
        }
      }

      .lyy {
        width: 20%;
        margin: auto 0;
        font-size: 18px;
      }

      .ggg {
        width: 1px;
        height: 1px;
      }
    }

    .buy {
      display: flex;
      justify-content: flex-end;
      margin-right: 12px;
      opacity: 1;
      margin-bottom: 20px;

      .getVip {
        width: 80px;
        height: 24px;
        background: linear-gradient(
          180deg,
          rgba(255, 227, 194, 1) 0%,
          rgba(251, 213, 176, 1) 100%
        );
        border-radius: 6px;
        color: rgba(213, 147, 110, 1);
        margin-left: auto;
        margin-right: 0;
        text-align: center;
        line-height: 24px;
        font-size: 14px;
        padding: 5px 8px;
      }
    }
  }
  .info-tab:nth-child(even) {
    margin-left: 20px;
  }
}
.craftsman {
  width: 94%;
  margin: 30px auto;
  border: 1px solid #f2cdb8;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 10px 6px;

  .tab-name {
    display: flex;
    justify-content: space-between;
    margin: 20px;

    .name-info {
      font-size: 14px;
      text-align: center;
      margin: auto 0;
    }

    .name-contend {
      width: 60%;
      font-size: 12px;
      background: #eff1f7;
      border-radius: 12px;
      box-sizing: border-box;
      padding: 5px 20px;
      color: #999999;
      word-wrap: break-word;
      word-break: normal;
    }
  }
  .craftsman-info {
    display: flex;
    flex-wrap: wrap;
  }
  .configs {
    display: flex;
    justify-content: space-around;
    width: 45%;
    height: 58px;
    margin: 15px 20px;
    box-sizing: border-box;
    padding: 10px;
    text-align: center;
    border-radius: 6px;
    background-image: url("~@/assets/personCenter/memberCenter/mb.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    border: none;
    .left {
      display: flex;
      flex: 1;
      .ycc {
        width: 30%;
        margin: auto 0;
        font-size: 24px;
        span {
          font-size: 14px;
        }
      }

      .lyc {
        width: 50%;
        font-size: 18px;
        margin: auto 0;
        color: rgba(153, 153, 153, 1);
      }
    }

    .lyy {
      width: 20%;
      margin: auto 0;
      font-size: 18px;
    }

    .ggg {
      width: 10px;
      height: 14px;
      position: absolute;
      right: 2px;
      top: 0;
    }
  }

  .con-info {
    display: flex;
    // justify-content: space-between;
    width: 45%;
    height: 58px;
    margin: 15px 20px;
    box-sizing: border-box;
    padding: 10px;
    text-align: center;
    border-radius: 6px;
    border: 2px solid rgba(201, 201, 201, 1);

    .left {
      display: flex;
      flex: 1;
      .ycc {
        width: 30%;
        margin: auto 0;
        font-size: 24px;
        span {
          font-size: 14px;
        }
      }

      .lyc {
        width: 50%;
        font-size: 18px;
        margin: auto 0;
        color: rgba(153, 153, 153, 1);
      }
    }

    .lyy {
      width: 20%;
      margin: auto 0;
      font-size: 18px;
    }

    .ggg {
      width: 1px;
      height: 1px;
    }
  }

  .buy {
    display: flex;
    justify-content: flex-end;
    margin-right: 12px;
    opacity: 1;
    margin-bottom: 20px;

    .getVip {
      width: 80px;
      height: 24px;
      background: linear-gradient(
        180deg,
        rgba(255, 227, 194, 1) 0%,
        rgba(251, 213, 176, 1) 100%
      );
      border-radius: 6px;
      color: rgba(213, 147, 110, 1);
      margin-left: auto;
      margin-right: 0;
      text-align: center;
      line-height: 24px;
      font-size: 14px;
      padding: 5px 8px;
    }
  }
  // }
  .info-tab:nth-child(even) {
    margin-left: 20px;
  }
}
</style>
